<!-- (c) 才酝通 -->
<template>
    <div id="content" class="container">
        <div class="user-icon"></div>
        <div class="user-info-box" @click="$router.push({name:'UserInfo'})" v-if="isLogin">
            <div class="user-avatar-box">
                <img :src="user.avatar" />
            </div>
            <div class="user-body">
                <div class="user-nickname">{{ user.nickName }}</div>
            </div>
            <div class="user-back">
                <img
                src="../../assets/img/new/back.png"
                style="width: 15px; height: 15px"
                />
            </div>
        </div>
        <div class="user-info-box" @click="goLogin" v-else>
            <div class="user-avatar-box">
                <img src="../../assets/img/default_header.png" />
            </div>
            <div class="user-body">
                <div class="login-button">请登录</div>
            </div>
            <div class="user-back">
                <img
                src="../../assets/img/new/back.png"
                style="width: 15px; height: 15px"
                />
            </div>
        </div>
        <div class="exam-box">
            <div class="exam-title">我的答题</div>
            <div class="exam-body">
                <div class="body-item" @click="goExam()">
                    <div>
                        <img src="../../assets/img/my-exam.png" alt="">
                    </div>
                    <div class="body-title">我的考试</div>
                </div>
                <div class="body-item" @click="goMock()">
                    <div>
                        <img src="../../assets/img/my-mock.png" alt="">
                    </div>
                    <div class="body-title">我的模考</div>
                </div>
                <div class="body-item" @click="goLearn()">
                    <div>
                        <img src="../../assets/img/my-practice.png" alt="">
                    </div>
                    <div class="body-title">我的练习</div>
                </div>
                <div class="body-item" @click="goWrong()">
                    <div>
                        <img src="../../assets/img/wrong.png" alt="">
                    </div>
                    <div class="body-title">错题本</div>
                </div>
                <div class="body-item" @click="goCollect()">
                    <div>
                        <img src="../../assets/img/collecting.png" alt="">
                    </div>
                    <div class="body-title">收藏集</div>
                </div>
            </div>
        </div>
        <div class="group-box mt-15">
            <div class="group-item" @click="openPage('user_protocol')">
                <div class="name">用户服务协议</div>
            </div>
            <div
                class="group-item"
                @click="openPage('user_private_protocol')"
            >
                <div class="name">用户隐私协议</div>
            </div>
            <div class="group-item" @click="openPage('about_us')">
                <div class="name">关于我们</div>
            </div>
        </div>

        <div class="power">Copyright 2024 才酝通 </div>
        <div class="support">才酝通 提供技术支持</div>
        <nav-footer type="user"></nav-footer>
    </div>
</template>
<script>
import { mapState } from "vuex";
import NavFooter from "../../components/footer";

export default {
    components: {
        NavFooter
    },
    computed: {
        ...mapState(["isLogin", "user", "func", "config"]),
    },
    methods: {
        goLogin() {
            this.$router.push({
                name: "Login",
                query: {
                url: window.location.href,
                },
            });
        },
        openPage(type) {
            this.$router.push({
                name: "Protocol",
                query: {
                    type: type,
                },
            });
        },
        goExam() {
            if (!this.isLogin) {
              this.goLogin();
              return;
            }else {
                this.$router.push({
                    name: "UserExam"
                });
            }
        },
        goMock() {
            if (!this.isLogin) {
              this.goLogin();
              return;
            }else {
                this.$router.push({
                    name: "UserMock"
                });
            }
        },
        goLearn() {
            if (!this.isLogin) {
              this.goLogin();
              return;
            }else {
                this.$router.push({
                    name: "UserLearn"
                });
            }
        },
        goWrong() {
            if (!this.isLogin) {
              this.goLogin();
              return;
            }else {
                this.$router.push({
                    name: 'AnswerErrors',
                })
            }
        },
        goCollect() {
            if (!this.isLogin) {
              this.goLogin();
              return;
            }else {
                this.$router.push({
                    name: 'AnswerCollects',
                })
            }
        }
    }
}

</script>

<style lang="less" scoped>
.container {
  background: #f3f6f9;
  .setting {
    width: 100%;
    img{
        width: 30px;
        height: 30px;
        position: absolute;
        right: 0px;
        padding: 20px;
    }
  }
  .user-icon {
        width: 100%;
        background: #ffffff;
        height: 25px;
        float: left;
        box-sizing: border-box;
        display: flex;
   }
   /* 用户信息 */
    .user-info-box {
    width: 100%;
    background: #ffffff;
    height: auto;
    float: left;
    box-sizing: border-box;
    padding: 25px 15px 30px 15px;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;

    .user-avatar-box {
        width: auto;
        height: 64px;
        box-sizing: border-box;
        padding-right: 15px;
        img {
        width: 64px;
        height: 64px;
        border-radius: 50%;
        }
    }

    .user-body {
        flex: 1;
        height: 64px;
        .user-nickname {
        width: 100%;
        height: auto;
        float: left;
        box-sizing: border-box;
        padding-top: 20px;
        padding-bottom: 15px;
        font-size: 16px;
        font-weight: 600;
        color: #333333;
        line-height: 16px;
        }

        .role-name {
        width: 100%;
        height: auto;
        float: left;
        font-size: 13px;
        font-weight: 400;
        color: #666666;
        line-height: 13px;
        }

        .login-button {
        width: 100%;
        height: auto;
        float: left;
        font-size: 16px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.7);
        line-height: 64px;
        }
      }
    }
    .exam-box{
        width: 100%;
        background: #ffffff;
        margin-top: 20px;
        float: left;
        box-sizing: border-box;
        padding: 25px 15px 0px 15px;
        .exam-title{
            font-weight: 600;
            color: #333;
        }
        .exam-body{
            width: 100%;
            margin-top: 20px;
            display: flex;
            flex-wrap: wrap;
            img{
                width: 33px;
                height: 33px;
            }
            .body-item{
                width: 25%;
                text-align: center;
                margin-bottom: 30px;
                font-size: 14px;
                .body-title{
                    margin-top: 8px;
                    color: #333;
                }
            }
        }
    }
    .group-box {
    width: 100%;
    height: auto;
    float: left;
    margin-bottom: 15px;
    background-color: white;
    .group-item {
      width: 100%;
      height: auto;
      float: left;
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      box-sizing: border-box;
      padding: 8px 15px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.03);
      .name {
        width: 150px;
        height: auto;
        float: left;
        line-height: 36px;
        font-size: 15px;
        color: #333333;
      }
    }
  }
  .power {
      width: 100%;
      height: auto;
      float: left;
      padding-top: 30px;
      padding-bottom: 10px;
      text-align: center;
      color: #999;
      font-size: 16px;
    }
    .support {
      width: 100%;
      height: auto;
      float: left;
      padding-top: 0px;
      padding-bottom: 90px;
      text-align: center;
      color: #999;
      font-size: 14px;
    }
}
</style>
